import axios from 'axios';

import User from '@/models/user.model';

import CreateUserRequest from '@/requests/create-user.request';
import UpdateUserRequest from '@/requests/update-user.request';
import ServerResponse from '@/responses/server.response';
import Toast from '@/toast';

export default class UserService {
	public static async get(): Promise<User[]> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/users`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at heinta allar brúkararnar`, error);

				Toast.error('Tað eydnaðist ikki at heinta brúkararnar');

				return false;
			});
	}

	public static async show(id: number): Promise<User> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/users/${id}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at heinta brúkaran við id ${id}`, error);

				Toast.error('Tað eydnaðist ikki at heinta brúkaran');

				return false;
			});
	}

	public static async create(createUserRequest: CreateUserRequest): Promise<ServerResponse> {
		return await axios
			.post(`${process.env.VUE_APP_API_URL}/users`, createUserRequest)
			.then((response) => {
				return response;
			})
			.catch((error) => {
				console.error('Ein feilur hendi, tá vit royndu at stovna brúkaran', error);

				Toast.error(error.response.data.message);

				return error.response;
			});
	}

	public static async update(id: number, updateUserRequest: UpdateUserRequest): Promise<ServerResponse> {
		return await axios
			.patch(`${process.env.VUE_APP_API_URL}/users/${id}`, updateUserRequest)
			.then((response) => {
				Toast.success('Dagføra brúkara', 'Brúkarin er dagførdur');

				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at rætta brúkaran við id ${id}`, error);

				Toast.error(error.response.data.message);

				return error.response.data;
			});
	}

	public static async delete(id: number): Promise<ServerResponse> {
		return await axios
			.delete(`${process.env.VUE_APP_API_URL}/users/${id}`)
			.then((response) => {
				Toast.success('Brúkarin strikaður', response.data.message);

				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at strika brúkaran við id ${id}`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}
}
