import Store from './store';

const { v4: uuid } = require('uuid');

type ToastType = {
	id?: string;
	title: string;
	body: string;
	success: boolean;
};

export default class Toast {
	// Add a Toast
	public static add(toast: ToastType): void {
		const id = uuid();
		const toasts = Store.get('toasts');

		Store.set('toasts', [...(toasts.value || []), { ...toast, id }]);

		setTimeout(() => {
			Store.set(
				'toasts',
				toasts.value.filter((toast: ToastType) => toast.id != id)
			);
		}, 5000);
	}

	// Remvoe a Toast
	public static remove(id: string): void {
		const toasts = Store.get('toasts');

		Store.set(
			'toasts',
			toasts.value.filter((toast: ToastType) => toast.id != id)
		);
	}

	// Get all Toasts
	public static all(): ToastType[] {
		return Store.get('toasts') as any;
	}

	// Add a Success
	public static success(title: string, body: string) {
		Toast.add({
			title,
			body,
			success: true,
		});
	}

	// Add an Error
	public static error(body: string) {
		Toast.add({
			title: 'Feilboð',
			body,
			success: false,
		});
	}
}
