import axios from 'axios';

import Meeting from '@/models/meeting.model';

import CreateMeetingRequest from '@/requests/create-meeting.request';
import UpdateMeetingRequest from '@/requests/update-meeting.request';
import ServerResponse from '@/responses/server.response';
import Toast from '@/toast';

export default class MeetingService {
	public static async get(): Promise<Meeting[]> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/meetings`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at heinta allar fundirnar`, error);

				Toast.error('Tað eydnaðist ikki at heinta fundirnar til verkætlanina');

				return false;
			});
	}

	public static async search(query: string): Promise<Meeting[]> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/meetings/search?q=${query}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at leita eftir fundum við query ${query}`, error);

				Toast.error('Tað eydnaðist ikki at leita í fundunum');

				return false;
			});
	}

	public static async show(id: number): Promise<Meeting> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/meetings/${id}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at heinta fundin við id ${id}`, error);

				return false;
			});
	}

	public static async create(createMeetingRequest: CreateMeetingRequest): Promise<Meeting> {
		return await axios
			.post(`${process.env.VUE_APP_API_URL}/meetings`, createMeetingRequest)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error('Ein feilur hendi, tá vit royndu at stovna fundin', error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async update(id: number, updateMeetingRequest: UpdateMeetingRequest): Promise<Meeting> {
		return await axios
			.patch(`${process.env.VUE_APP_API_URL}/meetings/${id}`, updateMeetingRequest)
			.then((response) => {
				Toast.success('Dagfør fund', 'Fundurin er dagførdur');

				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at rætta fundin við id ${id}`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async delete(id: number): Promise<ServerResponse> {
		return await axios
			.delete(`${process.env.VUE_APP_API_URL}/meetings/${id}`)
			.then((response) => {
				Toast.success('Fundur strikaður', response.data.message);

				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at strika fundin við id ${id}`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	// Attendees
	public static async addAttendee(id: number, userId: number): Promise<Meeting> {
		return await axios
			.post(`${process.env.VUE_APP_API_URL}/meetings/${id}/user/${userId}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(
					`Ein feilur hendi, tá vit royndu at leggja ein luttakara afturat fund við id ${id}`,
					error
				);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async removeAttendee(id: number, userId: number): Promise<ServerResponse> {
		return await axios
			.delete(`${process.env.VUE_APP_API_URL}/meetings/${id}/user/${userId}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at strika ein luttakara av fundi við id ${id}`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}
}
