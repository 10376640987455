import axios from 'axios';

import Store from '@/store';

import User from '@/models/user.model';
import ServerResponse from '@/responses/server.response';
import ResetPasswordRequest from '@/requests/reset-password.request';
import Toast from '@/toast';

export default class AuthService {
	public static async login(email: string, password: string, device_name = 'desktop'): Promise<boolean> {
		return await axios
			.post(`${process.env.VUE_APP_API_URL}/auth/login`, {
				email,
				password,
				device_name,
			})
			.then((response) => {
				const token: string = response.data.token;

				localStorage.setItem('token', token);

				return true;
			})
			.catch((error) => {
				console.error('Ein feilur hendi, tá vit royndu at innrita', error);

				return false;
			});
	}

	public static async logout() {
		return await axios
			.post(`${process.env.VUE_APP_API_URL}/auth/logout`)
			.then((response) => {
				Store.set('user', null);
				Store.set('projects', []);

				localStorage.removeItem('token');

				return response.data;
			})
			.catch((error) => {
				console.error('Ein feilur hendi, tá vit royndu at rita teg út', error);

				Toast.error('Tað eydnaðist ikki at rita út');

				return false;
			});
	}

	public static async me(reset = false): Promise<User> {
		// An Empty User object
		const emptyUser = {
			can: () => false,
			cannot: () => true,
		} as User;

		if (!localStorage.getItem('token')) {
			return emptyUser;
		}

		if (!reset && Store.getUser().value) {
			const user: User = Store.getUser().value;

			return Promise.resolve(user);
		}

		return await axios
			.get(`${process.env.VUE_APP_API_URL}/auth/me`)
			.then((response) => {
				const user: User = response.data;

				// Add User.can('PERMISSION')
				user.can = function (permissions: string | string[]) {
					if (!this.permission_names) {
						return false;
					}

					// Comma separated permissions
					if (typeof permissions == 'string') {
						permissions = permissions.split(',').map((permission: string) => permission.trim());
					}

					// Check that the user has all the permissions
					for (const permission of permissions) {
						if (!permission) {
							continue;
						}

						if (!this.permission_names.includes(permission.toLowerCase())) {
							return false;
						}
					}

					return true;
				};

				user.cannot = function (permissions: string | string[]) {
					return !user.can(permissions);
				};

				user.toString = function () {
					return `${this.first_name} ${this.last_name}`;
				};

				Store.set('user', user);

				return user;
			})
			.catch((error) => {
				console.error('Ein feilur hendi, tá vit royndu at heinta teg', error);

				// allow can() and cannot() on empty user
				return emptyUser;
			});
	}

	public static async forgotPassword(email: string): Promise<ServerResponse> {
		return await axios
			.post(`${process.env.VUE_APP_API_URL}/auth/forgot-password`, {
				email,
			})
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error('Ein feilur hendi, tá vit royndu at umbiða teldupost til nýtt loyniorð', error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async resetPassword(resetPasswordRequest: ResetPasswordRequest): Promise<ServerResponse> {
		return await axios
			.post(`${process.env.VUE_APP_API_URL}/auth/reset-password`, resetPasswordRequest)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error('Ein feilur hendi, tá vit royndu at áseta eitt nýtt loyniorð', error);

				return error.response;
			});
	}

	public static async loginViaUserLink(uuid: string): Promise<ServerResponse> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/user-link/${uuid}`)
			.then((response) => {
				const token: string = response.data;

				localStorage.setItem('token', token);

				return response;
			})
			.catch((error) => {
				console.error('Tað bar ikki til at innrita við leinkju', error);

				return error.response;
			});
	}
}
