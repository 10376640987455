
import Loader from './components/Loader.vue';
import SVG from './components/SVG.vue';

export default {
	components: {
		Loader,
		SVG,
	},
};
