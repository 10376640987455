import { createApp } from 'vue';
import App from './App.vue';
import Loader from './LoaderScreen.vue';

import axios from 'axios';
import mitt from 'mitt';

import router from './router';
import ClickAway from 'vue3-click-away';

import Store from './store';
import Toast from './toast';

import AuthService from './services/auth.service';
import UserService from './services/user.service';
import ProjectService from './services/project.service';

import '@/prototypes';

import './css/tailwind.css';
import Project from './models/project.model';
import MeetingService from './services/meeting.service';

const app = createApp(App);
const loader = createApp(Loader);

// Add global event bus
const emitter = mitt();
app.config.globalProperties.emitter = emitter;

// Add bearer token to axios requests
axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response.status === 401) {
			// router.replace({ name: 'Login' });
		}

		if (error.response.status == 403) {
			console.error(error.response.data.message);

			Toast.error(error.response.data.message);
		}

		return Promise.reject(error);
	}
);

window.addEventListener('keydown', (e) => {
	if (e.key == 'Escape') {
		emitter.emit('escape');
	}
});

// Add bearer token to axios requests
axios.interceptors.request.use(
	function (config) {
		config.headers = {
			Authorization: `Bearer ${localStorage.getItem('token')}`,
		};

		return config;
	},

	function (error) {
		return Promise.reject(error);
	}
);

// Reset header title and nav when navigating
router.beforeEach(async (to, from, next) => {
	Store.set('header.title', to.meta?.title);
	const projects = Store.get('projects');

	if (to.meta?.parent === 'Project') {
		const id = to.params.id;

		if (projects.value) {
			const findProject = projects.value.find((project: Project) => Number(project.id) === Number(id));

			if (findProject) {
				Store.set('header.title', 'Verkætlan: ' + findProject.title);
			}
		} else {
			await ProjectService.show(Number(id)).then((response) => {
				if (response) {
					Store.set('header.title', 'Verkætlan: ' + response.title);
				}
			});
		}
	}

	Store.set('header.nav', []);
	Store.set('header.options', []);
	Store.set('header.contextMenu', null);

	const permissions: string[] = to.meta.permissions as string[];

	const user = await AuthService.me();

	app.config.globalProperties.$user = user;

	if (permissions?.length && !user?.can(permissions)) {
		Store.set('user', null);
		Store.set('projects', []);
		localStorage.removeItem('token');
		next({ name: 'Login' });
		return;
	}

	next();
});

app.use(router);
app.use(ClickAway);

(async function () {
	loader.mount('#app');

	const user = await AuthService.me();

	if (!user.can('')) {
		loader.unmount();

		app.mount('#app');

		return;
	}

	if (user.can('view users')) {
		await UserService.get().then((response) => {
			if (response) {
				Store.set('users', response);
			}
		});
	}

	if (user.can('view projects')) {
		await ProjectService.get().then((response) => {
			if (response) {
				Store.set('projects', response);
			}
		});
	}

	if (user.can('view meetings')) {
		await MeetingService.get().then((response) => {
			if (response) {
				Store.set('meetings', response);
			}
		});
	}

	loader.unmount();

	app.mount('#app');
})();
