declare global {
	interface Number {
		format: (decimals: number, decimalSeparator: string, thousandsSeparator: string) => string;
		round: () => number;
	}

	interface Date {
		format: (format: string) => string;
		diff: (date: Date) => {
			milliseconds: number;
			seconds: number;
			minutes: number;
			hours: number;
			days: number;
			weeks: number;
		};
	}

	interface String {
		toRGB: (hex: string) => string;
	}
}

export default (function () {
	Number.prototype.format = function (decimals = 0, decimalSeparator = ',', thousandsSeparator = '.') {
		return this.toFixed(decimals)
			.replace('.', decimalSeparator)
			.replace(/\B(?=(\d{3})+(?!\d).?)/g, thousandsSeparator);
	};

	Date.prototype.format = function (format = '%d.%m.%y') {
		if (!this.getTime()) {
			return '';
		}

		const year = this.getFullYear();
		const month = this.getMonth();
		const day = this.getDate();
		const hours = this.getHours();
		const minutes = this.getMinutes();
		const seconds = this.getSeconds();

		const months = [
			'januar',
			'februar',
			'marts',
			'apríl',
			'maj',
			'juni',
			'juli',
			'august',
			'september',
			'oktober',
			'november',
			'desember',
		];

		return format
			.replace(/%y2/g, year.toString().substr(2, 2))
			.replace(/%y/g, String(year))

			.replace(/%mn3/g, months[month].substr(0, 3))
			.replace(/%mn/g, months[month])
			.replace(/%m/g, String(month + 1).padStart(2, '0'))
			.replace(/%-m/g, String(month + 1))

			.replace(/%d/g, String(day).padStart(2, '0'))
			.replace(/%-d/g, String(day))

			.replace(/%H12/g, String(hours % 12))
			.replace(/%a/g, hours < 12 ? 'AM' : 'PM')
			.replace(/%H/g, String(hours).padStart(2, '0'))
			.replace(/%-H/g, String(hours))

			.replace(/%M/g, String(minutes).padStart(2, '0'))
			.replace(/%-M/g, String(minutes))

			.replace(/%S/g, String(seconds).padStart(2, '0'))
			.replace(/%-S/g, String(seconds));
	};

	Number.prototype.round = function () {
		return this > 0 ? Math.floor(Number(this)) : Math.ceil(Number(this));
	};

	Date.prototype.diff = function (date) {
		const now = this.getTime();
		const then = date.getTime();

		const diff = then - now;

		const seconds = diff / 1000;
		const minutes = seconds / 60;
		const hours = minutes / 60;
		const days = hours / 24;
		const weeks = days / 7;

		return {
			milliseconds: diff.round(),
			seconds: seconds.round(),
			minutes: minutes.round(),
			hours: hours.round(),
			days: days.round(),
			weeks: weeks.round(),
		};
	};

	String.prototype.toRGB = function () {
		const hex = this.replace('#', '');
		if (hex.length != 6) {
			throw 'Only six-digit hex colors are allowed.';
		}

		let rgb = '';
		const rgbbHex = hex.match(/.{1,2}/g);
		if (rgbbHex) {
			rgb = `${parseInt(rgbbHex[0], 16)}, ${parseInt(rgbbHex[1], 16)}, ${parseInt(rgbbHex[2], 16)}`;
		}

		return rgb;
	};
})();
