import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/auth/Login.vue'),
		meta: {
			title: 'Rita inn',
		},
	},
	{
		path: '/link/:uuid',
		name: 'UserLink',
		component: () => import('../views/auth/LoginViaUserLink.vue'),
		meta: {
			title: 'Rita inn',
		},
	},
	{
		path: '/forgot-password',
		name: 'ForgotPassword',
		component: () => import('../views/auth/ForgotPassword.vue'),
		meta: {
			title: 'Gloymt loyniorð',
		},
	},

	{
		path: '/reset-password',
		name: 'ResetPassword',
		component: () => import('../views/auth/ResetPassword.vue'),
		meta: {
			title: 'Tómstilla loyniorð',
		},
	},
	{
		path: '/',
		name: 'Projects',
		component: () => import('../views/project/Index.vue'),
		meta: {
			title: 'Verkætlanir',
			permissions: ['view projects'],
		},
	},
	{
		path: '/project/create',
		name: 'ProjectCreate',
		component: () => import('../views/project/Create.vue'),
		meta: {
			title: 'Stovna verkætlan',
			parent: 'Project',
			permissions: ['create projects'],
		},
	},
	{
		path: '/project/:id/tasks/',
		name: 'ProjectTasks',
		component: () => import('../views/project/Tasks/Index.vue'),
		alias: '/project/:id',
		meta: {
			title: 'Uppgávur',
			parent: 'Project',
			permissions: ['view projects'],
		},
	},
	{
		path: '/project/:id/tasks/cards',
		name: 'ProjectTasksCards',
		component: () => import('../views/project/Tasks/Cards.vue'),
		meta: {
			title: 'Uppgávur',
			parent: 'Project',
			permissions: ['view projects'],
		},
	},
	{
		path: '/project/:id/info/',
		name: 'ProjectInfo',
		component: () => import('../views/project/Info.vue'),
		meta: {
			title: 'Upplýsingar',
			parent: 'Project',
			permissions: ['view projects'],
		},
	},
	{
		path: '/project/:id/members/',
		name: 'ProjectMembers',
		component: () => import('../views/project/Members.vue'),
		meta: {
			title: 'Limir',
			parent: 'Project',
			permissions: ['view projects'],
		},
	},
	{
		path: '/project/:id/limits/',
		name: 'ProjectLimits',
		component: () => import('../views/project/Limits.vue'),
		meta: {
			title: 'Karmar',
			parent: 'Project',
			permissions: ['view projects'],
		},
	},
	{
		path: '/project/:id/files/',
		name: 'ProjectFiles',
		component: () => import('../views/project/Files.vue'),
		meta: {
			title: 'Fílur',
			parent: 'Project',
			permissions: ['view files'],
		},
	},
	{
		path: '/project/:id/board/',
		name: 'ProjectBoard',
		component: () => import('../views/project/Board.vue'),
		meta: {
			title: 'Nevnd og áhugapartar',
			parent: 'Project',
			permissions: ['view projects'],
		},
	},
	{
		path: '/project/:id/statuses/',
		name: 'ProjectStatuses',
		component: () => import('../views/project/Statuses.vue'),
		meta: {
			title: 'Status',
			parent: 'Project',
			permissions: ['view statuses'],
		},
	},
	{
		path: '/meetings',
		name: 'Meetings',
		component: () => import('../views/meeting/Index.vue'),
		meta: {
			title: 'Fundir',
			permissions: ['view meetings'],
		},
	},
	{
		path: '/meetings/cards',
		name: 'MeetingCards',
		component: () => import('../views/meeting/Cards.vue'),
		meta: {
			title: 'Fundir',
			permissions: ['view meetings'],
		},
	},
	{
		path: '/users',
		name: 'Users',
		component: () => import('../views/user/Index.vue'),
		meta: {
			title: 'Brúkarar',
			permissions: ['view users'],
		},
	},
	{
		path: '/user',
		name: 'UserShow',
		component: () => import('../views/user/Show.vue'),
		meta: {
			title: 'Mín Brúkari',
		},
	},
	{
		path: '/notifications',
		name: 'Notifications',
		component: () => import('../views/notifications/Index.vue'),
		meta: {
			title: 'Fráboðanir',
		},
	},
	{ path: '/:pathMatch(.*)*', name: '404', component: () => import('../views/404/Index.vue') },
];

const router = createRouter({
	history: createWebHistory(process.env.VUE_APP_BASE_URL),
	routes,
});

export default router;
