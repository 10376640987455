import { computed, ComputedRef, reactive } from 'vue';
import User from './models/user.model';

type StoreData =
	| 'projects'
	| 'project'
	| 'meetings'
	| 'meeting'
	| 'tasks'
	| 'task'
	| 'users'
	| 'user'
	| 'toasts'
	| 'statuses'
	| 'notifications'
	| 'sidebar'
	| 'task.groups'
	| 'header.title'
	| 'header.nav'
	| 'header.options'
	| 'header.contextMenu'
	| 'draggingTaskOver'
	| 'tasksInDrag';

export default class Store {
	private static state = reactive({}) as any;

	public static get(key: StoreData): ComputedRef<any> {
		return computed(() => this.state[key]);
	}

	public static set(key: StoreData, value: any): void {
		this.state[key] = value;
	}

	public static getUser(): ComputedRef<User> {
		return Store.get('user');
	}

	public static setUser(user: User): void {
		Store.set('user', user);
	}
}
