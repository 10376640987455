
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		name: String,
	},

	setup(props) {
		const svg = require(`!svg-inline-loader!@/assets/${props.name}.svg`);

		return { svg };
	},
});
