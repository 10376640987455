import axios, { AxiosError } from 'axios';

import Project from '@/models/project.model';

import CreateProjectRequest from '@/requests/create-project.request';
import UpdateProjectRequest from '@/requests/update-project.request';
import AddMemberToProjectRequest from '@/requests/add-member-to-project.request';

import ServerResponse from '@/responses/server.response';
import Toast from '@/toast';
import Store from '@/store';

export default class ProjectService {
	public static async get(): Promise<Project[]> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/projects`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at heinta allar verkætlanirnar`, error);

				Toast.error('Tað bar ikki til at heinta verkætlanirnar');

				return false;
			});
	}

	public static async search(query: string): Promise<Project[]> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/projects/search?q=${query}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at leita eftir verkætlanum við query ${query}`, error);

				Toast.error('Tað bar ikki til at leita eftir verkætlanum');

				return false;
			});
	}

	public static async show(id: number): Promise<Project> {
		return await axios
			.get(`${process.env.VUE_APP_API_URL}/projects/${id}`)
			.then((response) => {
				Store.set('project', response.data);

				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at heinta verkætlanina við id ${id}`, error);

				Toast.error('Tað bar ikki til at heinta verkætlanina');

				return false;
			});
	}

	public static async create(createProjectRequest: CreateProjectRequest): Promise<Project> {
		return await axios
			.post(`${process.env.VUE_APP_API_URL}/projects`, createProjectRequest)
			.then((response) => {
				Toast.success('Stovna verkætlan', 'Verkætlanin er nú stovnað');

				return response.data;
			})
			.catch((error) => {
				console.error('Ein feilur hendi, tá vit royndu at stovna verkætlanina', error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async update(id: number, updateProjectRequest: UpdateProjectRequest): Promise<Project> {
		return await axios
			.patch(`${process.env.VUE_APP_API_URL}/projects/${id}`, updateProjectRequest)
			.then((response) => {
				Toast.success('Dagfør verkætlan', 'Verkætlan er dagførd');

				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at rætta verkætlanina við id ${id}`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async delete(id: number): Promise<ServerResponse> {
		return await axios
			.delete(`${process.env.VUE_APP_API_URL}/projects/${id}`)
			.then((response) => {
				Toast.success('Verkætlan strikað', response.data.message);

				return response.data;
			})
			.catch((error) => {
				console.error(`Ein feilur hendi, tá vit royndu at strika verkætlan við id ${id}`, error);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async addUser(
		projectId: number,
		userId: number,
		addMemberToProjectRequest: AddMemberToProjectRequest
	): Promise<ServerResponse | AxiosError> {
		return await axios
			.post(`${process.env.VUE_APP_API_URL}/projects/${projectId}/user/${userId}`, addMemberToProjectRequest)
			.then((response) => {
				return response.data;
			})
			.catch((error) => {
				console.error(
					`Ein feilur hendi, tá vit royndu at leggja brúkaran við id ${userId} afturat verkætlanini við ${projectId}`,
					error
				);

				Toast.error(error.response.data.message);

				return false;
			});
	}

	public static async removeUser(projectId: number, userId: number): Promise<ServerResponse> {
		return await axios
			.delete(`${process.env.VUE_APP_API_URL}/projects/${projectId}/user/${userId}`)
			.then((response) => {
				Toast.success('Limur strikaður', response.data.message);

				return response.data;
			})
			.catch((error) => {
				console.error(
					`Ein feilur hendi, tá vit royndu at strika brúkaran við id ${userId} av verkætlanini við ${projectId}`,
					error
				);

				Toast.error(error.response.data.message);

				return false;
			});
	}
}
